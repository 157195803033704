@import 'abstracts/variables';

.profile-menu-title {
  margin-left: @spacer * 2;
  margin-bottom: @spacer;
}

.profile-menu-name {
  margin-left: @spacer * 2;
}

.profile-content {
  flex-shrink: 1;
  min-width: 0;
  margin-left: @spacer;
  margin-right: @spacer;
  margin-bottom: @spacer;

  :global {
    .rs-form-group {
      margin-bottom: @form-group-margin-bottom;
    }
  }

  .profile-h1-alignment-left {
    padding-left: 3px;
  }

  :global(.rs-input-group-addon).profile-update-icon-input-picker {
    z-index: @zindex-input-group-icon + 1;
    @dropdown-toggle-position-right: @dropdown-toggle-padding-right + @picker-toggle-clean-width;
    @visual-margin-right: 7px;

    padding-right: @dropdown-toggle-position-right + @visual-margin-right;
  }

  .change-indication-text {
    color: @B600;
    font-size: @font-size-base - 2;
  }

  .supplier-profile {
    @input-height: (@padding-y * 2) + (@font-size-base * @line-height-base);
    @label-height: (@line-height-base * @font-size-base) +
      @form-control-label-margin-bottom;
    @uploader-dim: @input-height + @label-height;

    position: relative;

    :global {
      :local(.uploader-row-flex) {
        display: flex;
        flex-direction: row;
        margin-bottom: @spacer * 2;

        .rs-uploader-trigger.rs-uploader-trigger-customize {
          flex: 0 0 @uploader-dim;
          width: @uploader-dim;
          margin-right: @spacer;
          display: flex;
          flex-direction: column;
        }

        .logo-img {
          max-width: 100%;
          object-fit: contain;
        }
      }

      .rs-uploader-trigger-btn {
        width: @uploader-dim;
        height: @uploader-dim;
        padding: @spacer / 2;
        align-items: center;
        margin-top: 0;
      }

      .rs-form-group {
        min-width: 0;

        .rs-input-group {
          width: 100%; // Match with 100% width for company story on smaller screen sizes
        }
      }

      .rs-panel-body .rs-row:last-child > .rs-col > .rs-form-group {
        margin-bottom: 0;
      }
    }

    .description-error {
      border: 1px solid @red;
    }

    .supplier-shipping-details-container {

      // All FlexboxGrid.Item and
      // specifically target the Label fields FlexboxGrid.Item component
      .inputs-flex-row,
      .labels-flex-row {
        :global(.rs-flex-box-grid-item) {
          margin-inline-end: @spacer;
        }
      }

      .inputs-flex-row {

        // remove the number input HTML arrow buttons
        :global(.rs-input-number-btn-group-vertical) {
          display: none;
        }
      }
    }
  }
}

.trash-icon-container {
  align-self: center;
  max-width: 25px;

  // target the SVG icon
  [aria-label='trash'] {
    font-size: @spacer * 2;
    cursor: pointer;
    color: @red;
  }
}
