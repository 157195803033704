@import 'abstracts/variables.less';

.custom-button {
  display: inline-flex;
  padding: @spacer @spacer * 2;
  font-family: @headings-font-family;
  font-weight: @headings-font-weight !important;
  text-wrap: wrap !important;

  &.button-no-border {
    border: none;
    padding: 0 !important;
  }

  &.button-base-font-family {
    font-family: @font-family-base;
  }

  &.button-primary {
    justify-content: center;
    padding: @spacer @spacer * 3;
    border-radius: @border-radius;
    background-color: @primary;
    color: @dim-mid;
    text-align: center;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    @media @below-sm {
      padding: @spacer @spacer * 2;
    }
  }

  &.button-tertiary {
    color: @primary-darker;
    border-color: @primary-darker;
    text-decoration: none;
    transition: color @transition-delay ease-out;

    &.primary-padding {
      padding: @spacer @spacer * 3;

      @media @below-sm {
        padding: @spacer @spacer * 2;
      }
    }

    &:hover,
    &:focus {
      color: @primary-dark;
      border-color: @primary-dark;
      text-decoration: underline;
      transition: color @transition-delay ease-out;
    }
  }

  &.button-dim {
    color: @dim-mid;
    border-color: @dim-mid;
    text-decoration: none;
    transition: color @transition-delay ease-out;

    &.primary-padding {
      padding: @spacer @spacer * 3;

      @media @below-sm {
        padding: @spacer @spacer * 2;
      }
    }

    &:hover,
    &:focus {
      color: @dim-darker;
      border-color: @dim-mid;
      text-decoration: underline;
      transition: color @transition-delay ease-out;
    }
  }

  &.button-transparent {
    background: @B000;
    color: @text;

    &:hover {
      background: rgba(@B000, 0.99);
    }
  }

  &.button-caret {
    align-items: center;

    &::after {
      content: '';
      position: relative;
      left: 0;
      top: 1px; // Vertical alignment fix
      display: inline-block;
      width: @spacer;
      height: @spacer;
      margin-left: @spacer;
      background-repeat: no-repeat;
      background-size: contain;
      transition: left (@transition-delay / 2) ease-out;
    }

    &:hover::after {
      left: 2px;
      transition: left (@transition-delay / 2) ease-out;
    }

    &.button-transparent::after {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDcgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjk4NzMxMyAwLjc2OTgzMkMxLjEzMDk5IDAuNjEwMTg5IDEuMzY1NTIgMC41ODA5NDcgMS41NDIwMSAwLjY4OTk0NEwxLjYwNTE4IDAuNzM3MzEzTDUuOTgwMTggNC42NzQ4MUM2LjE1MTgyIDQuODI5MyA2LjE3MDkgNS4wODU3MSA2LjAzNzM5IDUuMjYyODRMNS45ODAxOCA1LjMyNTE5TDEuNjA1MTggOS4yNjI3QzEuNDI1NTggOS40MjQzMyAxLjE0ODk1IDkuNDA5NzcgMC45ODczMTMgOS4yMzAxOEMwLjg0MzYzNCA5LjA3MDUzIDAuODM5MTczIDguODM0MjMgMC45NjYwOTQgOC42NzAxNkwxLjAxOTgzIDguNjEyMzFMNS4wMzMgNC45OTkxM0wxLjAxOTgzIDEuMzg3NjlDMC44NjAxODkgMS4yNDQwMiAwLjgzMDk0NyAxLjAwOTQ4IDAuOTM5OTQ0IDAuODMzMDAxTDAuOTg3MzEzIDAuNzY5ODMyWiIgZmlsbD0iIzA1NEMyRSIgc3Ryb2tlPSIjMDU0QzJFIiBzdHJva2Utd2lkdGg9IjAuNSIvPgo8L3N2Zz4K');
    }

    &.button-tertiary::after {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgNSA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC41NDEzNjMgMC4zMzAxMDhDMC42MzQyNTQgMC4yMTU5NzMgMC43ODU4ODUgMC4xOTUwNjcgMC44OTk5ODUgMC4yNzI5OTNMMC45NDA4MjUgMC4zMDY4NTlMMy43NjkzNiAzLjEyMTkzQzMuODgwMzQgMy4yMzIzOCAzLjg5MjY3IDMuNDE1NjkgMy44MDYzNSAzLjU0MjMzTDMuNzY5MzYgMy41ODY5MUwwLjk0MDgyNSA2LjQwMTk4QzAuODI0NzExIDYuNTE3NTQgMC42NDU4NjYgNi41MDcxNCAwLjU0MTM2MyA2LjM3ODczQzAuNDQ4NDcxIDYuMjY0NiAwLjQ0NTU4NyA2LjA5NTY1IDAuNTI3NjQ0IDUuOTc4MzZMMC41NjIzODcgNS45MzdMMy4xNTY5OSAzLjM1MzhMMC41NjIzODcgMC43NzE4NDJDMC40NTkxNzQgMC42NjkxMjEgMC40NDAyNjkgMC41MDE0NDQgMC41MTA3MzggMC4zNzUyN0wwLjU0MTM2MyAwLjMzMDEwOFoiIGZpbGw9IiM3OEM0MDYiIHN0cm9rZT0iIzc4QzQwNiIgc3Ryb2tlLXdpZHRoPSIwLjMyMzI2MSIvPgo8L3N2Zz4=');
    }

    &.button-dim::after {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgNSA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC41NDEzNjMgMC4zMzAxMDhDMC42MzQyNTQgMC4yMTU5NzMgMC43ODU4ODUgMC4xOTUwNjcgMC44OTk5ODUgMC4yNzI5OTNMMC45NDA4MjUgMC4zMDY4NTlMMy43NjkzNiAzLjEyMTkzQzMuODgwMzQgMy4yMzIzOCAzLjg5MjY3IDMuNDE1NjkgMy44MDYzNSAzLjU0MjMzTDMuNzY5MzYgMy41ODY5MUwwLjk0MDgyNSA2LjQwMTk4QzAuODI0NzExIDYuNTE3NTQgMC42NDU4NjYgNi41MDcxNCAwLjU0MTM2MyA2LjM3ODczQzAuNDQ4NDcxIDYuMjY0NiAwLjQ0NTU4NyA2LjA5NTY1IDAuNTI3NjQ0IDUuOTc4MzZMMC41NjIzODcgNS45MzdMMy4xNTY5OSAzLjM1MzhMMC41NjIzODcgMC43NzE4NDJDMC40NTkxNzQgMC42NjkxMjEgMC40NDAyNjkgMC41MDE0NDQgMC41MTA3MzggMC4zNzUyN0wwLjU0MTM2MyAwLjMzMDEwOFoiIGZpbGw9IiMwNTRDMkUiIHN0cm9rZT0iIzA1NEMyRSIgc3Ryb2tlLXdpZHRoPSIwLjMyMzI2MSIvPgo8L3N2Zz4=');
    }

    &.button-primary::after {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgNSA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC41NDEzNjMgMC4zMzAxMDhDMC42MzQyNTQgMC4yMTU5NzMgMC43ODU4ODUgMC4xOTUwNjcgMC44OTk5ODUgMC4yNzI5OTNMMC45NDA4MjUgMC4zMDY4NTlMMy43NjkzNiAzLjEyMTkzQzMuODgwMzQgMy4yMzIzOCAzLjg5MjY3IDMuNDE1NjkgMy44MDYzNSAzLjU0MjMzTDMuNzY5MzYgMy41ODY5MUwwLjk0MDgyNSA2LjQwMTk4QzAuODI0NzExIDYuNTE3NTQgMC42NDU4NjYgNi41MDcxNCAwLjU0MTM2MyA2LjM3ODczQzAuNDQ4NDcxIDYuMjY0NiAwLjQ0NTU4NyA2LjA5NTY1IDAuNTI3NjQ0IDUuOTc4MzZMMC41NjIzODcgNS45MzdMMy4xNTY5OSAzLjM1MzhMMC41NjIzODcgMC43NzE4NDJDMC40NTkxNzQgMC42NjkxMjEgMC40NDAyNjkgMC41MDE0NDQgMC41MTA3MzggMC4zNzUyN0wwLjU0MTM2MyAwLjMzMDEwOFoiIGZpbGw9IiMwNTRDMkUiIHN0cm9rZT0iIzA1NEMyRSIgc3Ryb2tlLXdpZHRoPSIwLjMyMzI2MSIvPgo8L3N2Zz4=');
    }
  }
}

.link-button {
  color: @anchor-color;
  cursor: pointer;
  background: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
    color: @anchor-color;
  }
}

.highlighted-link-button {
  .link-button;

  color: @primary-darker;
  font-weight: 600;
}

button.sample-request-button {
  color: @primary-darker;
  border-color: @primary-darker;

  &:global(.rs-btn-ghost) {
    padding-left: @spacer * 2.5;
    padding-right: @spacer * 2.5;
  }
}

.product-specialist-cta-button {
  margin-top: @spacer * 1.5;

  &:global(.rs-btn-ghost) {
    &.on-products-catalog {
      padding: @spacer * 0.75 @spacer * 3;
    }

    &.on-spp {
      padding: @spacer * 0.75 @spacer * 2;
    }
  }

  @media @below-sm {
    margin-top: @spacer;
  }
}
