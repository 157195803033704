.image-container-height(@avatar-dimension) {
  height: @avatar-dimension;
  width: @avatar-dimension;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  &.image-shape-circle {
    img {
      border-radius: 50%;
    }
  }
}

.image-container-height-xl {
  .image-container-height(90px);
}

.image-container-height-lg {
  .image-container-height(80px);
}

.image-container-height-md {
  .image-container-height(70px);
}

.image-container-height-sm {
  .image-container-height(45px);
}
