@import 'abstracts/variables';

.warning-bar {
  background: @warning-lighter;
  border-radius: @border-radius;
  padding: @spacer @spacer * 2.5;
  color: @warning-dark;

  h6 {
    font-weight: 700;
    color: @warning-dark;
    margin: 0;
  }

  .supplier-link {
    text-decoration: underline;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  :global(.rs-icon) {
    color: @warning-dark;
  }
}

.popover {
  width: 252px;
}
