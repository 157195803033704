@import 'abstracts/variables';
@import 'MobileBlocker.module.less';

// Extra class for avoiding specificity
.backdrop.banner-backdrop {
  z-index: @z-index-cookie-consent-backdrop;
}

.banner-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  top: 0;
  left: 0;
  z-index: @z-index-cookie-consent;
  display: grid;
  place-items: center;

  .banner {
    max-width: 580px; // Match figma
    width: 100%;
    background: white;
    padding: @spacer * 3;
    border-radius: @border-radius;
    box-shadow: @default-box-shadow;

    .banner-footer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-top: @spacer * 3;

      .banner-footer-buttons {
        display: flex;
        gap: @spacer;
        margin-top: @spacer * 2;

        button {
          margin: 0;
          font-family: @headings-font-family;
          font-weight: 600;
          flex-basis: 50%;
        }

        @media @below-sm {
          flex-direction: column-reverse; // Ensure "Accept" button is on top
        }
      }
    }

    @media @below-md {
      max-width: 450px; // Match figma
    }
  }

  @media @below-sm {
    .banner {
      margin: 0 @spacer * 2;
      width: auto;
    }
  }
}

.cookie-manage-panel {
  margin: @spacer * 2 0;

  :global {
    .rs-panel-title,
    li {
      font-size: @font-size-base;
      color: @B800;
    }

    .rs-panel-header {
      padding: @spacer * 1.5 @spacer * 2;
      padding-right: @spacer * 4;

      & .rs-icon {
        top: 17px !important; // Aligning with text
      }
    }

    .rs-panel-body {
      padding-bottom: @spacer * 1.5;
      padding-left: @spacer * 1.5;

      ul {
        padding-left: @spacer * 2;
        margin-bottom: 0;
      }
    }
  }
}
