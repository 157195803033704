@import '../abstracts/variables';

:root {
  // Pass variable to javascript
  --screen-phone: @screen-sm;

  /* Rsuite CSS variables overrides */

  --rs-text-primary: @text;

  // Anchor tag colors:
  --rs-text-link: @anchor-color;
  --rs-text-link-hover: @anchor-color-hover;
  --rs-text-link-active: @anchor-color-hover;

  // Buttons:
  --rs-btn-primary-text: @dim-mid;
  --rs-btn-primary-bg: @primary;
  --rs-btn-primary-hover-bg: @primary-dark;
  --rs-btn-primary-active-bg: @primary-dark;
  --rs-btn-link-text: @anchor-color;
  --rs-btn-link-hover-text: @anchor-color-hover;

  // Ghost buttons
  --rs-btn-ghost-border: @dim-mid;
  --rs-btn-ghost-text: @dim-mid;
  --rs-btn-ghost-hover-border: @dim-darker;
  --rs-btn-ghost-hover-text: @dim-darker;

  // Inputs:
  --rs-input-focus-border: @primary-dark;

  // Nav:
  --rs-navs-selected: @primary-dark;
  --rs-sidenav-subtle-selected-text: @primary-darker;
  --rs-navs-bg-hover: @B050;

  // Checkbox:
  --rs-checkbox-checked-bg: @primary-dark;

  // Radio:
  --rs-radio-checked-bg: @primary-dark;

  // Listbox:
  --rs-listbox-option-hover-bg: @teal-mid;

  // Select:
  --rs-listbox-option-hover-text: @text;
  --rs-listbox-option-selected-text: @primary-darker;
  --rs-listbox-option-selected-bg: @teal-mid;
  --rs-picker-value: @primary-darker;
  --rs-picker-count-bg: @primary-darker;
  --rs-color-focus-ring: @teal-mid;
  --rs-state-focus-outline: @teal-mid;
  --rs-state-focus-shadow: @primary-dark;

  // Table:
  --rs-table-sort: @teal-darker;

  // Toggle:
  --rs-toggle-checked-bg: @primary-dark;
  --rs-toggle-checked-hover-bg: @primary-darker;

  // Error:
  --rs-message-error-bg: @red-lightest;
}
